<template>
  <svg
    width="16"
    height="24"
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74986 24C6.63621 24 6.52257 24 6.40892 24C5.95432 23.891 5.61338 23.4552 5.61338 22.9104L5.72702 15.9366L0.612829 12.9946C0.385531 12.8856 0.158234 12.5587 0.044585 12.3408C-0.0690638 12.0139 0.044585 11.687 0.271883 11.4691L8.2273 0.572591C8.45459 0.136733 9.02284 -0.0811968 9.47743 0.0277679C9.93203 0.245697 10.273 0.681556 10.273 1.11741V8.09116L15.3872 11.0332C15.6145 11.1422 15.8418 11.4691 15.9554 11.796C16.0691 12.1228 15.9554 12.4497 15.7281 12.6677L7.65905 23.5641C7.5454 23.7821 7.20446 24 6.74986 24ZM2.77216 11.687L7.43176 14.3021C7.7727 14.5201 8 14.9559 8 15.2828V19.4235L13.2278 12.3408L8.56824 9.72563C8.2273 9.5077 8 9.1808 8 8.74494V4.60429L2.77216 11.687Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconZap"
};
</script>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="nav-planner"
  >
    <path
      d="M22.3636 7.90909H20.5091C20.5091 7.40909 20.1 7 19.6 7C19.1 7 18.6909 7.40909 18.6909 7.90909H13.2364C13.2364 7.40909 12.8273 7 12.3273 7C11.8273 7 11.4182 7.40909 11.4182 7.90909H9.63636C7.62727 7.90909 6 9.53636 6 11.5455V20.6364C6 22.6455 7.62727 24.2727 9.63636 24.2727H22.3636C24.3727 24.2727 26 22.6455 26 20.6364V11.5455C26 9.53636 24.3727 7.90909 22.3636 7.90909ZM24.1818 20.6364C24.1818 21.6364 23.3636 22.4545 22.3636 22.4545H9.63636C8.63636 22.4545 7.81818 21.6364 7.81818 20.6364V11.5455C7.81818 10.5455 8.63636 9.72727 9.63636 9.72727H11.4182V10.6364C11.4182 11.1364 11.8273 11.5455 12.3273 11.5455C12.8273 11.5455 13.2364 11.1364 13.2364 10.6364V9.72727H18.6909V10.6364C18.6909 11.1364 19.1 11.5455 19.6 11.5455C20.1 11.5455 20.5091 11.1364 20.5091 10.6364V9.72727H22.3636C23.3636 9.72727 24.1818 10.5455 24.1818 11.5455V20.6364Z"
      fill="white"
    />
    <path
      d="M14.4273 14.4091H12.3364C12.2637 14.4091 12.2091 14.4636 12.2091 14.5364V15.3818C12.2091 15.4545 12.2727 15.5091 12.3364 15.5091H13.2546V19.7C13.2546 19.7727 13.3091 19.8273 13.3818 19.8273H14.4364C14.5091 19.8273 14.5637 19.7727 14.5637 19.7V14.5364C14.5546 14.4636 14.5 14.4091 14.4273 14.4091Z"
      fill="white"
    />
    <path
      d="M17.5637 14.3455C16.4728 14.3455 15.3182 15.1182 15.3182 17.1364C15.3182 19.1636 16.4728 19.9182 17.5637 19.9182C18.6728 19.9182 19.8091 19.1636 19.8091 17.1364C19.8091 15.1182 18.6728 14.3455 17.5637 14.3455ZM17.5637 18.7182C16.8273 18.7182 16.5728 18 16.5728 17.1364C16.5728 16.2818 16.8273 15.5455 17.5637 15.5455C18.3091 15.5455 18.5637 16.2818 18.5637 17.1364C18.5637 18 18.3091 18.7182 17.5637 18.7182Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCalendar",
  props: {
    revert: {
      type: Boolean,
      default: false
    }
  }
};
</script>

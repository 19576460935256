import { ref } from "vue";
import { db } from "@/firebase.js";

const useCollection = name => {
  const error = ref(null);
  const isPending = ref(false);

  const collection = db.collection(name);

  const addDoc = async doc => {
    error.value = null;
    isPending.value = true;
    try {
      const addedDoc = await collection.add(doc);
      isPending.value = false;
      return addedDoc;
    } catch (err) {
      console.log(err.message);
      error.value = "Could not send the message";
      isPending.value = false;
    }
  };
  return { collection, error, addDoc, isPending };
};

export default useCollection;

<template>
  <div class="inspiration-widget" onload="getRandomquote()">
    <h4>Get inspired</h4>
    <div class="inspContent" v-if="!loading">
      <div class="quote">
        {{ quote.text }}
      </div>

      <div v-if="quote.author" class="author">— {{ quote.author }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
function useRandomQuote() {
  const loading = ref(true);
  const quote = ref(null);

  (async () => {
    const res = await fetch(`https://type.fit/api/quotes`);
    const quotes = await res.json();

    quote.value = quotes[Math.floor(Math.random() * quotes.length)];
    loading.value = false;
  })();

  return { quote, loading };
}

export default {
  name: "Inspiration",
  setup() {
    const { quote, loading } = useRandomQuote();

    return {
      loading,
      quote
    };
  }
};
</script>

<style lang="scss" scoped>
.inspiration-widget {
  background: #f8f1ed;
  box-sizing: border-box;
  border-radius: $b9;
  height: 342px;
  padding: 24px 32px;
}
</style>

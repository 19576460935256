<template>
  <component
    v-if="!compact"
    class="mood-item"
    :class="modifierClasses"
    :is="moodComponent"
    :empty="!value || empty"
  />
  <div
    v-else
    class="mood-item mood-item-compact"
    :class="[`mood-item-compact--${value}`, modifierClasses]"
  ></div>
</template>

<script>
import { computed } from "vue";

import {
  MoodHappy,
  MoodContent,
  MoodSoso,
  MoodWorried,
  MoodStressed
} from "./icons";

const getMoodComponentFromMoodName = moodName =>
  ({
    happy: MoodHappy,
    content: MoodContent,
    soso: MoodSoso,
    worried: MoodWorried,
    stressed: MoodStressed
  }[moodName]);

export default {
  name: "MoodItem",
  props: {
    value: {
      type: String,
      default: undefined
    },
    empty: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    noHover: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const moodComponent = computed(() =>
      getMoodComponentFromMoodName(props.value ?? "happy")
    );

    const modifierClasses = computed(() => ({
      "mood-item--no-hover": props.noHover,
      "mood-item--disabled": props.disabled
    }));

    return { moodComponent, modifierClasses };
  }
};
</script>

<style lang="scss" scoped>
.mood-item {
  &--disabled {
    opacity: 25%;
  }

  &--no-hover {
    pointer-events: none;
  }

  &-compact {
    height: 12px;
    width: 12px;
    box-sizing: border-box;
    border: 2px solid $base-color;
    border-radius: 6px;

    &--happy {
      border-color: $color-mood-happy;
      background-color: $color-mood-happy;
    }

    &--content {
      border-color: $color-mood-content;
      background-color: $color-mood-content;
    }

    &--soso {
      border-color: $color-mood-soso;
      background-color: $color-mood-soso;
    }

    &--worried {
      border-color: $color-mood-worried;
      background-color: $color-mood-worried;
    }

    &--stressed {
      border-color: $color-mood-stressed;
      background-color: $color-mood-stressed;
    }
  }
}
</style>

<template>
  <form @submit.prevent="handleSubmit">
    <div class="newTaskDesc">
      <label>Task title*</label>
      <input
        type="text"
        class="form-control mainTitle noshad"
        v-model="form.name"
        placeholder="Pick a task title"
        required
        ref="projectList"
      />
      <label>Description</label>
      <textarea v-model="form.description"></textarea>
    </div>

    <div class="newTaskDetails">
      <div class="newTaskDetailsCont">
        <div class="newTaskDetailsItem">
          <label>Due date</label>
          <Datepicker v-model="form.dueDate" />
        </div>
        <div class="newTaskDetailsItem">
          <label>Est. time* (in hours)</label>
          <input v-model="form.estimatedTime" />
        </div>
      </div>
      <div class="newTaskDetailsCont">
        <div class="newTaskDetailsItem">
          <label>Assignee(s)</label>
          <select v-model="form.assignees" multiple>
            <option
              v-for="user in companyUsers"
              :value="user.userId"
              :key="user.userId"
              >{{ user.firstName }} {{ user.lastName }}</option
            >
          </select>
        </div>
        <div class="newTaskDetailsItem">
          <label>Project it belongs to</label>
          <select v-model="form.projectId">
            <option
              v-for="project in projects"
              :value="project.id"
              :key="project.id"
              >{{ project.name }}</option
            >
          </select>
        </div>
      </div>
      <h6>* required</h6>
    </div>

    <div class="newTaskActions">
      <BaseButton
        @click="closeOnSave = false"
        aria-label="create-task"
        modifiers="primary"
      >
        Create my task
      </BaseButton>
    </div>
  </form>
</template>

<script>
import { ref, inject, computed, reactive } from "vue";
import { useRoute } from "vue-router";
import Datepicker from "vue3-datepicker";

import { db, timestamp } from "@/firebase";
import getCollection from "@/composables/getCollection";
import useCompanies from "@/composables/useCompanies";
import getUser from "@/composables/getUser";

import BaseButton from "@/components/designsystem/BaseButton";

export default {
  components: { Datepicker, BaseButton },
  emits: ["create"],
  setup(props, { emit }) {
    const toast = inject("$toast");
    const route = useRoute();
    const { user } = getUser();

    const closeOnSave = ref(false);

    const { company } = useCompanies();
    const { documents: companyUsers } = getCollection("users", [
      "userId",
      "in",
      company.value.members
    ]);

    const { documents: projects } = getCollection(
      `companies/${route.params.companyId}/projects`
    );

    const initialForm = {
      name: "",
      description: "",
      estimatedTime: "",
      dueDate: new Date(),
      assignees: [user.value.uid],
      projectId: null
    };

    const form = reactive({ ...initialForm });
    const resetForm = () => Object.assign(form, initialForm);

    const selectedProject = computed(() =>
      projects.value.find(project => project.id === form.projectId)
    );

    const handleSubmit = async () => {
      if (!selectedProject.value) return;

      emit("create");

      const task = {
        name: form.name,
        description: form.description,
        estimatedTime: form.estimatedTime,
        assignees: form.assignees,
        projectId: form.projectId,
        dueDate: form.dueDate,
        priority: "medium",
        createdAt: timestamp(),
        userId: user.value.uid,
        list: selectedProject.value.lists[0].key
      };
      resetForm();

      await db
        .collection(`companies/${route.params.companyId}/tasks`)
        .add(task);

      await toast("Task created successfully!", {
        type: "success"
      });
    };

    return {
      companyUsers,
      form,
      resetForm,
      closeOnSave,
      handleSubmit,
      projects
    };
  },
  mounted() {
    const projectListRef = this.$refs.projectList;
    projectListRef.focus();
  }
};
</script>

<style lang="scss" scoped>
input.mainTitle {
  color: $base-color;
  font-family: GreycliffCF-Bold;
  font-size: 52px;
  height: 64px;
  line-height: 72px;
}

input.noshad,
input.noshad:focus {
  box-shadow: none;
  margin-bottom: 24px;
  padding: 0;
  width: 100%;
}

textarea {
  margin-top: 8px;
}

.newTask {
  background: white;
  border-radius: 9px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 48px 32px;
  position: relative;
  width: 506px;
}

.newTaskInside {
  height: 100%;
  width: 100%;
}

.newTaskDetailsCont {
  box-sizing: border-box;
  display: flex;
}

.newTaskDetailsItem {
  display: flex;
  flex-direction: column;
  margin: 12px 12px 12px 0;
  width: 50%;

  &:last-child {
    margin-right: 0;
  }
}

.newElements {
  align-items: center;
  display: flex;
}

.newItem {
  padding: 0 12px;
}

.newTaskDetails {
  margin-top: 16px;
}

.newTaskActions {
  margin-top: 32px;
}

.newTaskActions button {
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.v3dp__datepicker input {
  box-sizing: border-box;
  width: 100%;
}
</style>

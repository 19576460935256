import { ref, watchEffect } from "vue";
import { db } from "@/firebase.js";

const getCollection = (collection, query) => {
  const documents = ref(null);
  const error = ref(null);
  const loading = ref(true);

  let collectionRef = db.collection(collection).orderBy("createdAt");

  if (query) {
    collectionRef = collectionRef.where(...query);
  }

  const unsub = collectionRef.onSnapshot(
    snap => {
      const results = [];
      snap.docs.forEach(doc => {
        doc.data().createdAt && results.push({ ...doc.data(), id: doc.id });
      });
      documents.value = results;
      error.value = null;
      loading.value = false;
    },
    err => {
      console.error(err);
      documents.value = null;
      error.value = err.message;
      loading.value = false;
    }
  );

  watchEffect(onInvalidate => {
    onInvalidate(() => unsub());
  });

  return { error, loading, documents };
};

export default getCollection;

import { ref, watch } from "vue";
import { db } from "@/firebase";
import getUser from "@/composables/getUser";

const companiesRef = db.collection("companies");

const { user } = getUser();
const defaultCompany = ref(null);

export async function getUserCompanies() {
  if (!user.value) return [];

  const isOwner = companiesRef.where("owner", "==", user.value.uid).get();
  const isMember = companiesRef
    .where("members", "array-contains", user.value.uid)
    .get();

  const [ownerQuerySnapshot, memberQuerySnapshot] = await Promise.all([
    isOwner,
    isMember
  ]);

  const ownerArray = ownerQuerySnapshot.docs;
  const memberArray = memberQuerySnapshot.docs;

  return ownerArray.concat(memberArray);
}

export async function getUserDefaultCompany() {
  const companies = await getUserCompanies();
  if (!companies.length) return null;

  return {
    id: companies[0].id,
    ...companies[0].data()
  };
}

watch(
  user,
  async () => {
    defaultCompany.value = await getUserDefaultCompany();
  },
  { immediate: true }
);

export default function useDefaultCompany() {
  return defaultCompany;
}

<template>
  <div class="signupDetails">
    <div class="valueProp">
      <div class="valueContent">
        <span class="dawnLogo">
          <svg
            width="176"
            height="48"
            viewBox="0 0 176 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.1081 48C10.8152 48 0 37.2355 0 24.0015C0 10.7674 10.8152 0 24.1081 0C37.401 0 48.2162 10.7674 48.2162 24.0015C48.2162 37.2355 37.398 48 24.1081 48ZM24.1081 2.94352C12.4443 2.94352 2.9566 12.3893 2.9566 24.0015C2.9566 35.6107 12.4443 45.0594 24.1081 45.0594C35.7719 45.0594 45.2596 35.6137 45.2596 24.0015C45.2567 12.3893 35.7689 2.94352 24.1081 2.94352Z"
              fill="#2A1F60"
            />
            <path
              d="M35.4197 25.4703C34.6037 25.4703 33.9414 24.811 33.9414 23.9986C33.9414 18.6002 29.5301 14.2055 24.1048 14.2055C18.6823 14.2055 14.2681 18.5972 14.2681 23.9986C14.2681 24.811 13.6058 25.4703 12.7898 25.4703C11.9738 25.4703 11.3115 24.811 11.3115 23.9986C11.3115 16.9753 17.0503 11.262 24.1048 11.262C31.1592 11.262 36.898 16.9753 36.898 23.9986C36.898 24.8139 36.2357 25.4703 35.4197 25.4703Z"
              fill="#2A1F60"
            />
            <path
              d="M46.7555 25.4727H1.49881C0.682786 25.4727 0.0205078 24.8133 0.0205078 24.0009C0.0205078 23.1885 0.682786 22.5292 1.49881 22.5292H46.7555C47.5715 22.5292 48.2338 23.1885 48.2338 24.0009C48.2338 24.8133 47.5715 25.4727 46.7555 25.4727Z"
              fill="#2A1F60"
            />
            <path
              d="M35.4199 32.8321H22.8484C22.0324 32.8321 21.3701 32.1727 21.3701 31.3603C21.3701 30.5479 22.0324 29.8885 22.8484 29.8885H35.4199C36.2359 29.8885 36.8982 30.5479 36.8982 31.3603C36.8982 32.1727 36.2359 32.8321 35.4199 32.8321Z"
              fill="#2A1F60"
            />
            <path
              d="M16.1455 32.8321H12.7928C11.9767 32.8321 11.3145 32.1727 11.3145 31.3603C11.3145 30.5479 11.9767 29.8885 12.7928 29.8885H16.1455C16.9616 29.8885 17.6238 30.5479 17.6238 31.3603C17.6238 32.1727 16.9616 32.8321 16.1455 32.8321Z"
              fill="#2A1F60"
            />
            <path
              d="M29.5335 39.8786H18.6385C17.8224 39.8786 17.1602 39.2193 17.1602 38.4069C17.1602 37.5945 17.8224 36.9351 18.6385 36.9351H29.5335C30.3495 36.9351 31.0118 37.5945 31.0118 38.4069C31.0118 39.2193 30.3495 39.8786 29.5335 39.8786Z"
              fill="#2A1F60"
            />
            <path
              d="M59.625 15.0862H68.6456C73.4353 15.0862 75.431 17.126 75.431 22.0535C75.431 27.1134 75.2979 28.5969 74.7391 29.8156C73.7546 31.8819 71.9452 32.7561 68.5658 32.7561H59.625V15.0862ZM68.0336 29.2592C69.4439 29.2592 70.1623 28.9678 70.6413 28.2261C71.1203 27.4843 71.2533 26.5041 71.2533 23.7755C71.2533 21.2323 71.1203 20.2256 70.6679 19.5633C70.2421 18.901 69.5237 18.6361 68.14 18.6361H63.7228V29.2592H68.0336Z"
              fill="#2A1F60"
            />
            <path
              d="M77.986 24.0135C77.9594 20.9934 79.0504 20.2517 83.3611 20.2517C86.6607 20.2517 88.1242 20.7815 88.8161 22.1856C89.0556 22.6359 89.2418 23.8016 89.2418 24.7023V32.7558H85.7028L85.8358 30.9013H85.756C85.144 32.4379 84.2659 32.8882 81.8976 32.8882C78.6513 32.8882 77.4805 31.855 77.4805 28.9939C77.4805 26.2918 78.7577 25.2056 81.9775 25.2056C84.3723 25.2056 85.1174 25.5235 85.6496 26.6892H85.7028V24.4639C85.7028 22.9273 85.1972 22.477 83.4942 22.477C81.9508 22.477 81.6315 22.7419 81.5517 24.0135H77.986ZM81.0727 28.888C81.0727 30.2126 81.5251 30.504 83.4676 30.504C85.1972 30.504 85.7028 30.1331 85.7028 28.9145C85.7028 27.7488 85.1706 27.4309 83.2813 27.4309C81.5783 27.4309 81.0727 27.7753 81.0727 28.888Z"
              fill="#2A1F60"
            />
            <path
              d="M107.734 32.7563H102.599L101.534 27.8023C101.188 26.2128 101.082 25.683 100.63 23.3782H100.523L100.337 24.4909C100.257 24.8352 100.177 25.2061 100.124 25.577C100.071 25.8684 99.8846 26.7956 99.6717 27.8023L98.6074 32.7563H93.3919L90.8906 20.3846H94.3765L95.3078 25.63C95.5739 27.0341 95.6005 27.193 95.9198 29.1799L96.1061 30.372H96.1593L96.4254 29.1799L96.6649 27.9878C96.6649 27.9348 96.9576 26.7162 97.1971 25.63L98.4211 20.3846H102.705L103.903 25.63C104.169 26.7956 104.355 27.5904 104.435 27.9878L104.674 29.1799L104.94 30.372H105.02L105.206 29.1799C105.499 27.299 105.552 27.0076 105.819 25.63L106.776 20.3846H110.209L107.734 32.7563Z"
              fill="#2A1F60"
            />
            <path
              d="M115.531 20.3841L115.398 22.6359H115.478C116.09 20.9405 117.181 20.2517 119.23 20.2517C121.172 20.2517 122.503 20.808 123.115 21.8677C123.434 22.4505 123.541 23.0068 123.541 24.1724V32.7558H120.002V25.497C120.002 23.4572 119.682 23.0333 118.139 23.0333C116.383 23.0333 115.558 23.8016 115.558 25.4705V32.7558H111.992V20.3841H115.531Z"
              fill="#2A1F60"
            />
            <rect x="136" y="4" width="40" height="18" rx="3" fill="#FFF" />
            <path
              d="M148.108 12.769C149.092 12.961 149.584 13.471 149.584 14.299C149.584 14.809 149.392 15.22 149.008 15.532C148.624 15.844 148.114 16 147.478 16H144.184V9.7H147.478C148.066 9.7 148.537 9.844 148.891 10.132C149.245 10.42 149.422 10.795 149.422 11.257C149.422 12.007 148.984 12.511 148.108 12.769ZM145.597 10.699V12.301H147.046C147.4 12.301 147.664 12.226 147.838 12.076C148.012 11.926 148.099 11.734 148.099 11.5C148.099 11.254 148.015 11.059 147.847 10.915C147.679 10.771 147.454 10.699 147.172 10.699H145.597ZM147.136 15.001C147.448 15.001 147.709 14.926 147.919 14.776C148.135 14.62 148.243 14.401 148.243 14.119C148.243 13.873 148.153 13.663 147.973 13.489C147.799 13.315 147.538 13.228 147.19 13.228H145.597V15.001H147.136ZM156.029 10.789H152.645V12.238H155.813V13.291H152.645V14.911H156.029V16H151.232V9.7H156.029V10.789ZM162.444 9.7V10.852H160.473V16H159.06V10.852H157.089V9.7H162.444ZM167.853 16L167.367 14.659H164.739L164.253 16H162.777L165.297 9.7H166.8L169.329 16H167.853ZM165.108 13.633H166.998L166.053 11.041L165.108 13.633Z"
              fill="#2A1F60"
            />
          </svg>
        </span>
        <h2>Plan work with your team’s wellbeing in mind</h2>
        <h5>
          Dawn lets you get ~27 days of productivity back with an alternate way
          to manage day-to-day work. Allow your team to produce their best work
          by being in the mental space that lets them.
        </h5>
      </div>
    </div>
    <div class="fifty">
      <div class="stepOne">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.signupDetails {
  background: linear-gradient(113.61deg, #f9feff 0%, #ffcfb1 100%);
  display: flex;
  height: 100vh;
  margin: 0;
  padding: 0 142px;
  align-items: center;
  font-family: GreycliffCF-Bold;
  font-size: $t18;
  z-index: 2;
}
.fifty {
  align-items: center;
  background-color: white;
  border-radius: 9px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  display: flex;
  font-family: GreycliffCF-Bold;
  height: 642px;
  justify-content: center;
  left: 0;
  padding: 48px;
}

.valueProp {
  height: 100%;
  width: 60%;
}
h2 {
  font-family: Rational-Display-Book;
  font-size: 64px;
  line-height: 68px;
}
.valueContent {
  align-content: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: center;
  padding: 0 142px 0 0;
}
.dawnLogo {
  display: flex;
  margin-bottom: 32px;
}

.dividerLine {
  background-color: $base-color;
  height: 1px;
  width: 432px;
}
.boxEnter {
  width: 432px;
}
span.under {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
</style>

<template>
  <div class="onboarding-widget">
    <div class="onboarding-widget__title-block">
      <h3 v-if="userName">Delighted to see you here, {{ userName }}</h3>
      <p class="larger" style="max-width: 350px">
        Let’s have a quick look at what you can do inside Dawn at this point in
        time 👇
      </p>
    </div>
    <div v-if="isCurrentStep(1)">
      <div class="onboarding-widget__content-block">
        <h5>Personal Planner</h5>
        <div class="onboarding-widget__content-block__wrapper">
          <div class="onboarding-widget__content-block__wrapper__text">
            <p class="larger nomargin">
              Plan work by simply dragging your tasks from your backlog. In
              addition, set a time limit, which is designed to work for you and
              your well-being.
            </p>
          </div>
          <div class="onboarding-widget__content-block__wrapper__image">
            <img src="@/assets/imgs/feature-ob-01.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCurrentStep(2)">
      <div class="onboarding-widget__content-block">
        <h5>Mood Insider</h5>
        <div class="onboarding-widget__content-block__wrapper">
          <div class="onboarding-widget__content-block__wrapper__text">
            <p class="larger nomargin">
              Track how your mood evolves overtime and receive suggestions when
              it's starting to lean a little too close towards unhealthy
              fatigue.
            </p>
          </div>
          <div class="onboarding-widget__content-block__wrapper__image">
            <img src="@/assets/imgs/feature-ob-02.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="isCurrentStep(3)">
      <div class="onboarding-widget__content-block">
        <h5>Plan mindfully</h5>
        <div class="onboarding-widget__content-block__wrapper">
          <div class="onboarding-widget__content-block__wrapper__text">
            <p class="larger nomargin">
              Plan work by simply dragging your tasks from your backlog. In
              addition, set a time limit, which is designed to work for you and
              your well-being.
            </p>
          </div>
          <div class="onboarding-widget__content-block__wrapper__image">
            <img src="@/assets/imgs/feature-ob-01.png" alt="" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="onboarding-widget__nav-items">
      <button
        class="circularNav outlined clickable"
        :disabled="isFirstStep"
        @click.prevent="previousStep"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.7332 22.68C18.3576 23.085 17.7249 23.1087 17.3199 22.7332L11.6627 17.4857C10.7824 16.6798 10.7824 15.3202 11.6627 14.5143L17.3199 9.26683C17.7249 8.89125 18.3576 8.91503 18.7332 9.31994C19.1087 9.72486 19.085 10.3576 18.68 10.7332L13.0135 15.9892C13.0083 15.994 13.0054 15.9975 13.0041 15.9994C13.0039 15.9996 13.0042 15.9992 13.0041 15.9994C13.0042 15.9996 13.0039 16.0004 13.0041 16.0006C13.0054 16.0025 13.0082 16.006 13.0135 16.0108L13.0201 16.0168L18.68 21.2668C19.085 21.6424 19.1087 22.2751 18.7332 22.68Z"
            fill="#2A1F60"
          />
        </svg>
      </button>
      <button class="circularNav clickable" @click.prevent="nextStep">
        <template v-if="isLastStep">Finish</template>
        <svg
          v-else
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.2668 9.31995C13.6424 8.91504 14.2751 8.89126 14.6801 9.26684L20.3373 14.5143C21.2176 15.3202 21.2176 16.6798 20.3373 17.4857L14.6801 22.7332C14.2751 23.1088 13.6424 23.085 13.2668 22.6801C12.8913 22.2751 12.915 21.6424 13.3199 21.2668L18.9865 16.0108C18.9917 16.006 18.9946 16.0025 18.9959 16.0006C18.9961 16.0004 18.9958 16.0008 18.9959 16.0006C18.9958 16.0004 18.9961 15.9996 18.9959 15.9994C18.9946 15.9975 18.9918 15.994 18.9865 15.9892L18.9799 15.9832L13.32 10.7332C12.915 10.3576 12.8913 9.72487 13.2668 9.31995Z"
            fill="#2A1F60"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

import getUser from "@/composables/getUser";

export default {
  emits: ["finish"],
  setup(props, { emit }) {
    const step = ref(1);
    const isCurrentStep = value => step.value === value;
    const isFirstStep = computed(() => step.value === 1);
    const isLastStep = computed(() => step.value === 2);
    const previousStep = () => (step.value -= 1);
    const nextStep = () => {
      if (!isLastStep.value) {
        step.value += 1;
      } else {
        emit("finish");
      }
    };

    const { userDetails } = getUser();
    const userName = computed(
      () => userDetails.value && userDetails.value.firstName
    );

    return {
      step,
      isCurrentStep,
      isFirstStep,
      isLastStep,
      previousStep,
      nextStep,
      userName
    };
  }
};
</script>

<style lang="scss" scoped>
h5 {
  margin: 48px 0 0 0;
}
.topnone {
  margin-top: 0;
}
.rounded {
  border-radius: 150px;
  border: none;
}
p.larger {
  font-family: GreycliffCF-Regular;
  font-size: 18px;
  line-height: 30px;
  margin-top: 24px;
}
.nomargin {
  margin: 0;
}
.onboarding-widget {
  &__title-block {
    background: #fff2ea;
    border-radius: 16px 16px 0 0;
    box-shadow: inset 1px 1px 0px rgba(39, 35, 67, 0.1),
      inset 0px 0px 1px rgba(39, 35, 67, 0.15),
      inset 0px 0px 6px rgba(39, 35, 67, 0.15);
    padding: 48px 56px;

    h5 {
      margin-bottom: 0;
    }
  }
  &__content-block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 56px;
    width: 100%;

    &__wrapper {
      align-items: center;
      box-sizing: border-box;
      display: flex;

      &__text {
        margin-right: 16px;
        width: 70%;
      }
      &__image {
        // width: 40%;

        img {
          width: 180px;
        }
      }
    }
  }
  &__nav-items {
    display: flex;
    padding: 12px 56px 48px 56px;

    .circularNav {
      align-items: center;
      background: $base-sunrise;
      border-radius: 150px;
      border: none;
      color: $base-color;
      display: flex;
      height: 40px;
      justify-content: center;
      margin-right: 4px;
      padding: 0 16px;
      width: auto;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: #c8c4e5;
      }

      &:disabled {
        opacity: 50%;
        filter: grayscale(1);
        pointer-events: none;
      }
    }
    .outlined {
      background: #fcdbc3;
    }
  }
}
</style>

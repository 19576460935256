<template>
  <Modal v-if="showOnboarding" @close="closeOnboarding">
    <div class="new-onboarding">
      <CloseModal @close="closeOnboarding" />
      <NewOnboarding @finish="closeOnboarding" />
    </div>
  </Modal>
</template>

<script>
import { ref } from "vue";

import Modal from "@/components/commons/Modal";
import CloseModal from "@/components/designsystem/CloseModal";
import NewOnboarding from "@/components/commons/NewOnboarding.vue";

const ONBOARDING_ALREADY_SHOWN_STORAGE_KEY = "dawn-onboarding-already-shown";

export default {
  name: "OnboardingModal",
  components: {
    Modal,
    CloseModal,
    NewOnboarding
  },
  setup() {
    const onboardingAlreadyShown =
      !!localStorage.getItem(ONBOARDING_ALREADY_SHOWN_STORAGE_KEY) ?? false;
    const showOnboarding = ref(!onboardingAlreadyShown);

    const closeOnboarding = () => {
      showOnboarding.value = false;
      localStorage.setItem(ONBOARDING_ALREADY_SHOWN_STORAGE_KEY, "true");
    };

    return {
      showOnboarding,
      closeOnboarding
    };
  }
};
</script>

<style lang="scss" scoped>
.new-onboarding {
  background: white;
  border-radius: 16px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: 620px;
}
</style>

<template>
  <Enter>
    <div class="mainTitle">
      Let's log you in!
    </div>
    <h5 class="nomargin">Time to get back to it :)</h5>
    <div class="login-box">
      <form action="#" @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            required
            placeholder="Email"
            v-model="email"
            ref="projectList"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            required
            placeholder="password"
            v-model="password"
          />
        </div>
        <div class="error">{{ error }}</div>
        <div class="form-group">
          <BaseButton
            v-if="!isPending"
            :disabled="!canLogin"
            aria-label="create-project"
            modifiers="primary"
            @click="closeOnSave = false"
          >
            Log In
          </BaseButton>
          <BaseButton v-else aria-label="loading" disabled>
            Loading...
          </BaseButton>
          <!-- <button v-if="!isPending" :disabled="!canLogin">Log In</button> -->
          <!-- <button v-else disabled>Loading...</button> -->
        </div>
      </form>
    </div>
    <div class="separatorLine"></div>
    <p>
      No account yet?
      <router-link :to="{ name: 'Signup' }" class="under">Signup</router-link>
      instead.
    </p>
  </Enter>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

import useLogin from "@/composables/useLogin";
import { getUserDefaultCompany } from "@/composables/useDefaultCompany";

import BaseButton from "@/components/designsystem/BaseButton";
import Enter from "./Enter";

export default {
  components: { Enter, BaseButton },
  setup() {
    const router = useRouter();
    const { error, login, isPending } = useLogin();
    const email = ref("");
    const password = ref("");

    const canLogin = computed(() => email.value && password.value);

    const handleSubmit = async () => {
      await login(email.value, password.value);
      if (!error.value) {
        const defaultCompany = await getUserDefaultCompany();
        router.replace({
          name: "CompanyOverview",
          params: { companyId: defaultCompany.id }
        });
      }
    };

    return {
      email,
      password,
      canLogin,
      handleSubmit,
      error,
      isPending
    };
  },
  mounted() {
    const projectListRef = this.$refs.projectList;
    projectListRef.focus();
  }
};
</script>

<style lang="scss" scoped>
h5 {
  font-family: "GreycliffCF-Regular";
  margin: 24px 0 42px 0;
  font-size: 21px;
  font-weight: 100;
  line-height: 32px;
}
.mainTitle {
  color: $base-color;
  font-family: Rational-Display-Book;
  font-size: 48px;
  font-weight: 900;
  line-height: 62px;
  box-sizing: border-box;
  margin-bottom: 0;
  width: 100%;
}
.nomargin {
  margin-top: 0;
}
.login-box {
  display: flex;
}
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
label {
  height: auto;
  margin: 0;
}
input {
  border-radius: 6px;
  box-sizing: border-box;
  margin: 8px 0 16px 0;
  width: 432px;
}
a.under {
  color: $base-color;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
</style>

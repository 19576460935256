<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5455 6H10.6364C8.62727 6 7 7.62727 7 9.63636V22.3636C7 24.3727 8.62727 26 10.6364 26H21.5455C23.5545 26 25.1818 24.3727 25.1818 22.3636V9.63636C25.1818 7.62727 23.5545 6 21.5455 6ZM23.3636 22.3636C23.3636 23.3636 22.5455 24.1818 21.5455 24.1818H10.6364C9.63636 24.1818 8.81818 23.3636 8.81818 22.3636V20.5455H11.7C12.0727 21.6 13.0909 22.3636 14.2727 22.3636H16.0909H17.9091C19.0909 22.3636 20.1091 21.6 20.4818 20.5455H23.3636V22.3636ZM23.3636 18.7273H19.7273C19.2273 18.7273 18.8182 19.1364 18.8182 19.6364C18.8182 20.1364 18.4091 20.5455 17.9091 20.5455H16.0909H14.2727C13.7727 20.5455 13.3636 20.1364 13.3636 19.6364C13.3636 19.1364 12.9545 18.7273 12.4545 18.7273H8.81818V9.63636C8.81818 8.63636 9.63636 7.81818 10.6364 7.81818H21.5455C22.5455 7.81818 23.3636 8.63636 23.3636 9.63636V18.7273Z"
      fill="white"
    />
    <path
      d="M19.5455 10.4546H12.6364C12.1364 10.4546 11.7273 10.8636 11.7273 11.3636C11.7273 11.8636 12.1364 12.2727 12.6364 12.2727H19.5455C20.0455 12.2727 20.4546 11.8636 20.4546 11.3636C20.4546 10.8636 20.0455 10.4546 19.5455 10.4546Z"
      fill="white"
    />
    <path
      d="M19.5455 14.0909H12.6364C12.1364 14.0909 11.7273 14.5 11.7273 15C11.7273 15.5 12.1364 15.9091 12.6364 15.9091H19.5455C20.0455 15.9091 20.4546 15.5 20.4546 15C20.4546 14.5 20.0455 14.0909 19.5455 14.0909Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDrawer"
};
</script>

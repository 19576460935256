<template>
  <Modal :show="user && !shownToday" @close="closeModal">
    <div class="newPrompt">
      <h5>Welcome back, {{ user.displayName }} 👋<br /><br /></h5>
      <MoodTrackerToday />
      <router-link
        custom
        :to="{ name: 'CompanyOverview' }"
        v-slot="{ navigate }"
      >
        <BaseButton
          @click="closeModalAndGoToOverview(navigate)"
          aria-label="close"
          modifiers="secondary"
        >
          See my overview
        </BaseButton>
      </router-link>
      <CloseModal @close="closeModal" />
    </div>
  </Modal>
</template>

<script>
import getUser from "@/composables/getUser";
import useDailyFlag from "@/composables/useDailyFlag";

import MoodTrackerToday from "@/components/mood/MoodTrackerToday";
import Modal from "@/components/commons/Modal";
import CloseModal from "@/components/designsystem/CloseModal";
import BaseButton from "@/components/designsystem/BaseButton";

const DAILY_PROMPT_STORAGE_KEY = "daily-prompt";

export default {
  components: {
    Modal,
    CloseModal,
    MoodTrackerToday,
    BaseButton
  },
  setup() {
    const [shownToday, markAsShownToday] = useDailyFlag(
      DAILY_PROMPT_STORAGE_KEY
    );
    const { user } = getUser();

    const closeModal = () => markAsShownToday();
    const closeModalAndGoToOverview = navigate => {
      closeModal();
      navigate();
    };

    return {
      user,
      shownToday,
      closeModal,
      closeModalAndGoToOverview
    };
  }
};
</script>

<style lang="scss" scoped>
.newPrompt {
  background: white;
  border-radius: 6px;
  box-shadow: 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 1px rgb(0 0 0 / 10%),
    0px 2px 12px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  display: flex;
  height: auto;
  flex-direction: column;
  max-width: 416px;
  padding: 24px;
  position: relative;
  width: auto;
}
.closeModal {
  background: none;
  right: 8px;
  top: 8px;
}
h5 {
  line-height: 24px;
  margin: 0;
  width: 88%;
}
button {
  width: fit-content;
}
.mood-tracker-today {
  background: #f8f1ed;
  box-shadow: none;
  height: auto;
  margin: 0 0 24px 0;
}
.mood-tracker-today__subtitle {
  display: none !important;
}
</style>

import { ref, watch } from "vue";

import { db } from "@/firebase";
import getUser from "./getUser";

export default function useMoodForRange(startDateRef, endDateRef) {
  const { userDetails } = getUser();
  const moods = ref({});
  let unsubscribe = null;
  watch(
    [userDetails, startDateRef, endDateRef],
    () => {
      if (!userDetails.value) return;
      if (unsubscribe) unsubscribe();
      unsubscribe = db
        .collection(`users/${userDetails.value.id}/moods`)
        .where("__name__", ">=", startDateRef.value.format("YYYY-MM-DD"))
        .where("__name__", "<=", endDateRef.value.format("YYYY-MM-DD"))
        .onSnapshot(({ docs }) => {
          moods.value = docs.reduce((acc, document) => {
            acc[document.id] = document.data().value;

            return acc;
          }, {});
        });
    },
    { immediate: true }
  );

  return { moods };
}

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3636 24H9.63636C7.62727 24 6 22.4089 6 20.4444V11.5556C6 9.59111 7.62727 8 9.63636 8H22.3636C24.3727 8 26 9.59111 26 11.5556V20.4444C26 22.4089 24.3727 24 22.3636 24ZM9.63636 9.77778C8.63636 9.77778 7.81818 10.5778 7.81818 11.5556V20.4444C7.81818 21.4222 8.63636 22.2222 9.63636 22.2222H22.3636C23.3636 22.2222 24.1818 21.4222 24.1818 20.4444V11.5556C24.1818 10.5778 23.3636 9.77778 22.3636 9.77778H9.63636Z"
      fill="white"
    />
    <path
      d="M25.0909 14.2222H19.8545C18.9 14.2222 17.9636 13.84 17.2818 13.1822L13.5364 9.51999C13.1818 9.17332 13.1818 8.61332 13.5364 8.26665C13.8909 7.91999 14.4636 7.91999 14.8182 8.26665L18.5636 11.9289C18.9091 12.2578 19.3818 12.4444 19.8545 12.4444H25.0909C25.5909 12.4444 26 12.8444 26 13.3333C26 13.8222 25.5909 14.2222 25.0909 14.2222Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconFolder"
};
</script>

<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label>Project title*</label>
        <input
          type="text"
          class="form-control mainTitle noshad"
          v-model="form.name"
          placeholder="Enter a title here"
          required
          ref="projectList"
        />
      </div>

      <div class="form-group">
        <label>Description</label>
        <input type="text" class="form-control" v-model="form.description" />
      </div>

      <div class="form-group">
        <label>Priority</label>
        <select v-model="form.priority">
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
          <option value="urgent">Urgent</option>
        </select>
      </div>

      <div class="form-group">
        <label>Assignee(s)</label>
        <select v-model="form.assignees" multiple>
          <option
            v-for="user in companyUsers"
            :value="user.userId"
            :key="user.userId"
          >
            {{ user.firstName }} {{ user.lastName }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <h6>* required</h6>
      </div>
      <BaseButton aria-label="create-project" modifiers="primary">
        Create my Project
      </BaseButton>
    </form>
  </div>
</template>

<script>
import { reactive, inject } from "vue";
import { useRoute } from "vue-router";

import { db, timestamp } from "@/firebase";
import getUser from "@/composables/getUser";
import getCollection from "@/composables/getCollection";
import useCompanies from "@/composables/useCompanies";

import BaseButton from "@/components/designsystem/BaseButton";

export default {
  components: { BaseButton },
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    }
  },
  emits: ["create"],
  setup(props, { emit }) {
    const toast = inject("$toast");
    const route = useRoute();
    const { user, userDetails } = getUser();

    const { company } = useCompanies();
    const { documents: companyUsers } = getCollection("users", [
      "userId",
      "in",
      company.value.members
    ]);

    const initialForm = {
      name: "",
      description: "",
      priority: "medium",
      assignees: [user.value.uid]
    };

    const form = reactive({ ...initialForm });
    const resetForm = () => Object.assign(form, initialForm);

    const handleSubmit = async () => {
      emit("create");

      const project = {
        name: form.name,
        description: form.description,
        priority: form.priority,
        assignees: form.assignees,
        lists: [
          {
            title: "To-Do",
            key: new Date().toISOString() + "-0"
          },
          {
            title: "Doing",
            key: new Date().toISOString() + "-1"
          },
          {
            title: "Done",
            key: new Date().toISOString() + "-2"
          }
        ],
        createdAt: timestamp(),
        userId: user.value.uid
      };

      resetForm();

      await db
        .collection(`companies/${route.params.companyId}/projects`)
        .add(project);

      await toast("Project created successfully!", {
        type: "success"
      });
    };

    return {
      userDetails,
      form,
      companyUsers,
      handleSubmit
    };
  },
  mounted() {
    const projectListRef = this.$refs.projectList;
    projectListRef.focus();
  }
};
</script>
<style lang="scss" scoped>
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
input.mainTitle {
  color: $base-color;
  font-family: GreycliffCF-Bold;
  font-size: 52px;
  height: 64px;
  line-height: 72px;
}
input.noshad,
input.noshad:focus {
  box-shadow: none;
  margin-bottom: 12px;
  padding: 0;
}
.topnone {
  margin-top: 0;
}
</style>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9956 27.9898C9.38162 27.9898 4 22.6097 4 15.9956C4 9.38162 9.38162 4 15.9956 4C22.6097 4 27.9913 9.38162 27.9913 15.9956C27.9898 22.6097 22.6097 27.9898 15.9956 27.9898ZM15.9956 5.74397C10.3423 5.74397 5.74397 10.3423 5.74397 15.9956C5.74397 21.649 10.3423 26.2473 15.9956 26.2473C21.649 26.2473 26.2473 21.649 26.2473 15.9956C26.2473 10.3423 21.6476 5.74397 15.9956 5.74397Z"
      fill="#FFB77D"
    />
    <path
      d="M21.556 16.8662C21.0749 16.8662 20.684 16.4752 20.684 15.9942C20.684 13.4087 18.5796 11.3043 15.9941 11.3043C13.4087 11.3043 11.3057 13.4087 11.3057 15.9942C11.3057 16.4752 10.9148 16.8662 10.4338 16.8662C9.95271 16.8662 9.56177 16.4752 9.56177 15.9942C9.56177 12.4466 12.448 9.56036 15.9956 9.56036C19.5431 9.56036 22.4294 12.4466 22.4294 15.9942C22.4279 16.4767 22.0385 16.8662 21.556 16.8662Z"
      fill="#FFB77D"
    />
    <path
      d="M27.128 16.8676H4.88212C4.40107 16.8676 4.01013 16.4767 4.01013 15.9956C4.01013 15.5146 4.40107 15.1236 4.88212 15.1236H27.128C27.609 15.1236 28 15.5146 28 15.9956C28 16.4767 27.6105 16.8676 27.128 16.8676Z"
      fill="#FFB77D"
    />
    <path
      d="M21.556 20.5009H15.3765C14.8955 20.5009 14.5045 20.1099 14.5045 19.6289C14.5045 19.1478 14.8955 18.7569 15.3765 18.7569H21.556C22.037 18.7569 22.428 19.1478 22.428 19.6289C22.428 20.1099 22.0385 20.5009 21.556 20.5009Z"
      fill="#FFB77D"
    />
    <path
      d="M12.0818 20.5009H10.4338C9.95271 20.5009 9.56177 20.1099 9.56177 19.6289C9.56177 19.1478 9.95271 18.7569 10.4338 18.7569H12.0818C12.5629 18.7569 12.9538 19.1478 12.9538 19.6289C12.9538 20.1099 12.5629 20.5009 12.0818 20.5009Z"
      fill="#FFB77D"
    />
    <path
      d="M18.6625 23.9802H13.307C12.826 23.9802 12.4351 23.5892 12.4351 23.1082C12.4351 22.6271 12.826 22.2362 13.307 22.2362H18.6625C19.1435 22.2362 19.5345 22.6271 19.5345 23.1082C19.5345 23.5892 19.1435 23.9802 18.6625 23.9802Z"
      fill="#FFB77D"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDawnLogo"
};
</script>

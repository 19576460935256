<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mood-stressed"
    :class="{ 'mood-stressed--empty': empty }"
  >
    <path
      class="mood-stressed__background"
      d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26Z"
    />
    <g class="mood-stressed__face">
      <path
        d="M19 10L16.473 10.6242C16.3311 10.6585 16.2116 10.7118 16.128 10.778C16.0443 10.8442 16 10.9207 16 10.9988C16 11.0768 16.0443 11.1533 16.128 11.2195C16.2116 11.2857 16.3311 11.339 16.473 11.3733L19 12"
      />
      <path
        d="M9 12L11.527 11.3733C11.6689 11.339 11.7884 11.2857 11.872 11.2195C11.9557 11.1532 12 11.0768 12 10.9988C12 10.9207 11.9557 10.8442 11.872 10.778C11.7884 10.7118 11.6689 10.6585 11.527 10.6242L9 10"
      />
      <path
        d="M9.02246 18C9.48049 17.3997 10.1894 16.8942 11.069 16.5408C11.9486 16.1873 12.9637 16 13.9995 16C15.0353 16 16.0505 16.1873 16.9301 16.5408C17.8097 16.8942 18.5186 17.3997 18.9766 18"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MoodStressed",
  props: {
    empty: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.mood-stressed {
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;

  &__background {
    fill: $color-mood-stressed;
    stroke: $color-mood-stressed;
  }

  &__face {
    stroke-width: 1.3;
    stroke: $base-color;
  }

  &--empty {
    .mood-stressed__background {
      fill: none;
      stroke: $base-color;
    }

    &:hover {
      .mood-stressed__background,
      .mood-stressed__face {
        stroke: $color-mood-stressed;
      }
    }
  }
}
</style>

import { computed } from "vue";

import useMoodForRange from "./useMoodForRange";

export default function useMoodStatisticsForRange(startDateRef, endDateRef) {
  const { moods } = useMoodForRange(startDateRef, endDateRef);
  const moodEntries = computed(() => Object.values(moods.value ?? {}));

  const daysCount = computed(() =>
    Math.abs(endDateRef.value.diff(startDateRef.value, "days"))
  );
  const entriesCount = computed(() => moodEntries.value.length);

  const statistics = computed(() =>
    moodEntries.value.reduce(
      (acc, mood) => {
        acc[mood] += 1;
        return acc;
      },
      { happy: 0, content: 0, soso: 0, worried: 0, stressed: 0 }
    )
  );

  return { daysCount, entriesCount, statistics };
}

import { ref, readonly } from "vue";
import dayjs from "dayjs";

const hasAlreadyBeenShownToday = storageKey => {
  const lastShownDateString = localStorage.getItem(storageKey);
  if (!lastShownDateString) return false;

  const lastShownDate = dayjs(lastShownDateString);
  if (!lastShownDate.isValid()) return false;

  return !lastShownDate.isBefore(dayjs(), "day");
};

export default function useDailyFlag(storageKey) {
  const lastShownStorageKey = `${storageKey}-last-shown`;
  const flagRef = ref(hasAlreadyBeenShownToday(lastShownStorageKey));

  const markFlag = () => {
    flagRef.value = true;
    localStorage.setItem(lastShownStorageKey, dayjs().format("YYYY-MM-DD"));
  };

  return [readonly(flagRef), markFlag];
}

import { ref, watch, computed } from "vue";
import { auth } from "@/firebase.js";
import getCollection from "./getCollection";

const user = ref(auth.currentUser);

auth.onAuthStateChanged(newUser => {
  user.value = newUser;
});

let allUserDetails = ref(null);
watch(user, () => {
  const { documents } = getCollection("users", [
    "userId",
    "==",
    user.value.uid
  ]);
  allUserDetails = documents;
});

const userDetails = computed(
  () => allUserDetails.value && allUserDetails.value[0]
);

const getUser = () => {
  return {
    user,
    userDetails
  };
};

export default getUser;

<template>
  <div v-if="!isMobile">
    <router-view />
  </div>
  <div v-else class="mobile-message flex flex-column">
    <h2>Sorry, this app is not supported on Mobile/Tablet Devices</h2>
    <p>To use this app, please use a computer</p>
  </div>
</template>

<script>
import { ref, onBeforeUnmount } from "vue";

export default {
  name: "App",
  setup() {
    const isMobile = ref(false);

    const checkScreen = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 840) {
        isMobile.value = true;
        return;
      }
      isMobile.value = false;
    };

    window.addEventListener("resize", checkScreen);
    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkScreen);
    });

    return { isMobile };
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/_reset.scss";
@import "@/assets/styles/common.scss";
@import "@/assets/styles/mobile.scss";
@import "@/assets/styles/mainbody.scss";
@import "@/assets/styles/projects.scss";
@import "@/assets/styles/work.scss";
@import "@/assets/styles/settings.scss";
@import "@/assets/styles/taskdetails.scss";

@font-face {
  font-family: "GreycliffCF-Regular";
  src: url(./assets/fonts/greycliff-cf-regular.eot);
  src: url(./assets/fonts/greycliff-cf-regular.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/greycliff-cf-regular.woff2) format("woff2"),
    url(./assets/fonts/greycliff-cf-regular.woff) format("woff"),
    url(./assets/fonts/greycliff-cf-regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GreycliffCF-Medium";
  src: url(./assets/fonts/greycliff-cf-medium.eot);
  src: url(./assets/fonts/greycliff-cf-medium.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/greycliff-cf-medium.woff2) format("woff2"),
    url(./assets/fonts/greycliff-cf-medium.woff) format("woff"),
    url(./assets/fonts/greycliff-cf-medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GreycliffCF-Bold";
  src: url(./assets/fonts/greycliff-cf-bold.eot);
  src: url(./assets/fonts/greycliff-cf-bold.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/greycliff-cf-bold.woff2) format("woff2"),
    url(./assets/fonts/greycliff-cf-bold.woff) format("woff"),
    url(./assets/fonts/greycliff-cf-bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Rational-Display-Book";
  src: url(./assets/fonts/RationalDisplay-Bold.eot);
  src: local("Rational Display Bold"), local("RationalDisplay-Bold"),
    url(./assets/fonts/RationalDisplay-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/RationalDisplay-Bold.woff2) format("woff2"),
    url(./assets/fonts/RationalDisplay-Bold.woff) format("woff"),
    url(./assets/fonts/RationalDisplay-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
</style>

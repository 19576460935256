<template>
  <Modal :show="currentModal !== ''" @close="closeModal">
    <div class="modal-card">
      <CloseModal @close="closeModal" />

      <div class="modal-card__container">
        <NewTask v-if="currentModal === 'task'" @create="closeModal" />
        <NewProject
          v-else-if="currentModal === 'project'"
          @create="closeModal"
        />
      </div>
    </div>
  </Modal>

  <div class="new-nav">
    <transition name="fade">
      <div class="new-nav__elements" v-if="isExpanded">
        <BaseButton
          @click="openModal('task')"
          aria-label="new-task"
          class="new-nav__elements-button"
          modifiers="primary"
        >
          New task
        </BaseButton>
        <BaseButton
          @click="openModal('project')"
          aria-label="new-project"
          class="new-nav__elements-button"
          modifiers="primary"
        >
          New project
        </BaseButton>
      </div>
    </transition>

    <div class="new-nav__button" @click="toggleExpanded">
      <IconPlus
        class="new-nav__plus-icon"
        :class="{ 'new-nav__plus-icon--open': isExpanded }"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import CloseModal from "@/components/designsystem/CloseModal";
import NewTask from "@/components/commons/NewTask.vue";
import NewProject from "@/components/commons/NewProject.vue";
import Modal from "@/components/commons/Modal";
import BaseButton from "@/components/designsystem/BaseButton";
import IconPlus from "@/components/icons/IconPlus.vue";

export default {
  components: {
    Modal,
    IconPlus,
    NewTask,
    NewProject,
    CloseModal,
    BaseButton
  },
  setup() {
    const isExpanded = ref(false);
    const toggleExpanded = () => (isExpanded.value = !isExpanded.value);

    const currentModal = ref("");
    const openModal = name => {
      currentModal.value = name;
      isExpanded.value = false;
    };
    const closeModal = () => (currentModal.value = "");

    const onCreateTask = closeOnSave => closeOnSave && this.closeModal();

    return {
      isExpanded,
      toggleExpanded,
      currentModal,
      openModal,
      closeModal,
      onCreateTask
    };
  }
};
</script>

<style lang="scss" scoped>
.new-nav {
  position: fixed;
  right: $s24;
  bottom: $s24;
  z-index: 100;
  display: flex;
  height: 56px;
  color: white;
  font-family: GreycliffCF-Bold;
  font-size: 18px;
  transition: ease-in-out 0.35s all;

  &__button {
    align-items: center;
    background: $base-sunrise;
    border-radius: 150px;
    display: flex;
    justify-content: center;
    height: 56px;
    width: 56px;
    z-index: 10;

    &:hover {
      background: #fcdbc3;
    }
  }

  &__plus-icon {
    cursor: pointer;
    transition: transform 0.3s ease;

    &--open {
      transform: rotate(45deg);
    }
  }

  &__elements {
    position: relative;
    right: -55px;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 0 0 16px;
    background: $base-color;
    border-radius: 150px;
    color: white;
    font-family: GreycliffCF-Bold;
    font-size: 18px;
    transition: ease-in-out 0.25s all;
    padding-right: 56px;
    bottom: 0;

    &-button {
      padding: 0 12px;
      background-color: none;

      &:hover {
        background: none;
        color: $base-sunrise;
      }
    }
  }
}

.modal-card {
  background: white;
  border-radius: 9px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 48px 32px;
  position: relative;
  width: 506px;

  &__container {
    height: 100%;
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

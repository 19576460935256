import "./utils/polyfills";

import { createApp } from "vue";
import { MotionPlugin } from "@vueuse/motion";
import VueClickAway from "vue3-click-away";
import dayjs from "dayjs";
import updateLocalePlugin from "dayjs/plugin/updateLocale";
import isTodayPlugin from "dayjs/plugin/isToday";
import weekOfYearPlugin from "dayjs/plugin/weekOfYear";
import weekdayPlugin from "dayjs/plugin/weekday";
import DKToast from "vue-dk-toast";

import App from "./App.vue";
import router from "./router";
import { auth } from "@/firebase.js";

dayjs.extend(updateLocalePlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(weekOfYearPlugin);
dayjs.extend(weekdayPlugin);

// English locale starts weeks on Sunday, update it to start weeks on Monday
dayjs.updateLocale("en", {
  weekStart: 1
});

let app;

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(MotionPlugin)
      .use(VueClickAway)
      .use(DKToast, {
        duration: 3000,
        positionY: "bottom", // 'top' or 'bottom'
        positionX: "center", // 'left', 'right' or 'center'
        disableClick: false,
        class: "toast-styles", // Added to each toast,
        max: 3
      })
      // .use(store)
      .use(router)
      .mount("#app");
  }
});

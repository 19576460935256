<template>
  <div class="mood-tracker-statistics">
    <h4 class="mood-tracker-statistics__title">
      <span>Mood overview</span>
      <router-link
        v-if="!noLink"
        class="mood-tracker-statistics__see-more"
        :to="{ name: 'UserMood' }"
        >See more</router-link
      >
    </h4>
    <h6 class="mood-tracker-statistics__subtitle">
      Last {{ numberOfWeeks }} weeks
    </h6>
    <div class="mood-tracker-statistics__graph mood-graph">
      <div class="mood-graph__entries">
        <div
          class="mood-graph__entry"
          v-for="(value, mood) in statistics"
          :key="mood"
        >
          <div
            class="mood-graph__entry-bar-wrapper"
            :style="{ height: `${barHeight}px` }"
          >
            <div
              class="mood-graph__entry-bar"
              :class="`mood-graph__entry-bar--${mood}`"
              :style="{
                height: `${barHeight - 36}px`,
                transform: getBarTransform(mood)
              }"
            >
              <MoodItem
                class="mood-graph__entry-bar__icon"
                :value="mood"
                no-hover
              />
            </div>
          </div>
          <div class="mood-graph__entry-separator"></div>
          <span class="mood-graph__entry-value">{{ value }}</span>
        </div>
      </div>
    </div>
    <p v-if="!noText" class="mood-tracker-statistics__info-text">
      During the last <b>{{ numberOfWeeks }}</b> weeks, you completed your mood
      <b>{{ entriesCount }} times</b>.
    </p>
  </div>
</template>

<script>
import { computed, toRef } from "vue";

import useMoodStatisticsForRange from "@/composables/useMoodStatisticsForRange";

import MoodItem from "@/components/mood/MoodItem.vue";

export default {
  name: "MoodTrackerStatistics",
  components: { MoodItem },
  props: {
    startDate: {
      type: Object,
      required: true
    },
    endDate: {
      type: Object,
      required: true
    },
    barHeight: {
      type: Number,
      default: 120
    },
    noLink: {
      type: Boolean,
      default: false
    },
    noText: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { statistics, entriesCount } = useMoodStatisticsForRange(
      toRef(props, "startDate"),
      toRef(props, "endDate")
    );

    const numberOfWeeks = computed(() =>
      Math.ceil(props.endDate.diff(props.startDate, "weeks", true))
    );

    const maxValue = computed(() =>
      Math.max(...Object.values(statistics.value))
    );

    const getBarTransform = mood => {
      const translateValue =
        100 - (statistics.value[mood] * 100) / maxValue.value;
      return `translateY(${isNaN(translateValue) ? 100 : translateValue}%)`;
    };

    return {
      maxValue,
      numberOfWeeks,
      statistics,
      entriesCount,
      getBarTransform
    };
  }
};
</script>

<style lang="scss" scoped>
.mood-tracker-statistics {
  box-sizing: border-box;
  border-radius: $b9;
  padding: 24px 32px;

  &__title {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__see-more {
    @include sm-caps;
    &:hover {
      text-decoration: underline;
    }
  }

  &__subtitle {
    margin: 0;
    margin-bottom: 24px;
  }

  &__info-text {
    margin-top: 24px;

    b {
      font-weight: 700;
    }
  }
}

.mood-graph {
  box-sizing: border-box;

  &__entries {
    display: flex;
    flex-direction: row;
  }

  &__entry {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;

    &-bar-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;
    }

    &-bar {
      position: relative;
      box-sizing: border-box;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      border: 3px solid $base-color;
      border-bottom: none;
      width: 28px;
      transition: transform 500ms ease;

      &--happy {
        border-color: $color-mood-happy;
        background-color: $color-mood-happy;
      }

      &--content {
        border-color: $color-mood-content;
        background-color: $color-mood-content;
      }

      &--soso {
        border-color: $color-mood-soso;
        background-color: $color-mood-soso;
      }

      &--worried {
        border-color: $color-mood-worried;
        background-color: $color-mood-worried;
      }

      &--stressed {
        border-color: $color-mood-stressed;
        background-color: $color-mood-stressed;
      }

      &__icon {
        position: absolute;
        top: -36px;
        left: -3px;
      }
    }

    &-icon {
      margin-bottom: 12px;
    }

    &-separator {
      width: 100%;
      height: 2px;
      background-color: $base-color;
    }

    &-value {
      margin-top: 12px;
      background-color: white;
    }
  }

  &__bar {
    padding: 12px;
  }
}
</style>

<template>
  <div class="personalDashboard">
    <div class="boxesBlock">
      <div class="block no-card widerBlock">
        <div class="titlesBlock">
          <div class="greetings">Hey {{ user.displayName }} 👋</div>
          <h2>
            You have
            <span class="orangeTitle" v-if="tasks"
              >{{ tasks.length }} tasks</span
            >
            to do, for an estimated total of
            <span class="orangeTitle"
              >{{ totalTaskHours }}
              {{ totalTaskHours === 1 ? "hour" : "hours" }}</span
            >
          </h2>
        </div>
      </div>
      <div class="block no-card shorterBlock">
        <MoodTrackerStatistics
          noText
          :start-date="twoWeeksAgo"
          :end-date="today"
        />
      </div>
    </div>

    <div class="boxesBlock">
      <div class="block no-card widerBlock">
        <div v-if="tasks">
          <TaskList :tasks="tasks" />
        </div>
      </div>
      <div class="block no-card shorterBlock">
        <Inspiration />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import TaskList from "@/components/overview/personal/TaskList.vue";
import MoodTrackerStatistics from "@/components/mood/MoodTrackerStatistics.vue";
import Inspiration from "@/components/overview/personal/Inspiration.vue";

import getUser from "@/composables/getUser";
import getCollection from "@/composables/getCollection";

export default {
  components: {
    TaskList,
    MoodTrackerStatistics,
    Inspiration
  },
  setup() {
    let route = useRoute();
    const companyId = ref(route.params.companyId);

    const today = ref(dayjs());
    const twoWeeksAgo = computed(() => today.value.subtract(2, "weeks"));
    const { user } = getUser();
    const {
      error,
      documents: tasks
    } = getCollection(`companies/${companyId.value}/tasks`, [
      "userId",
      "==",
      user.value.uid
    ]);

    const totalTaskHours = computed(() =>
      (tasks.value || [])
        .map(({ estimatedTime }) => {
          return parseInt(estimatedTime || "0", 10);
        })
        .reduce((acc, estimatedTime) => acc + estimatedTime, 0)
    );

    return {
      user,
      today,
      twoWeeksAgo,
      error,
      tasks,
      totalTaskHours
    };
  }
};
</script>

<style lang="scss" scoped>
.shorterBlock {
  width: 45%;
}
.widerBlock {
  width: 55%;
}
.personalDashboard {
  margin-top: 56px;
}
</style>

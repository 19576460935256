<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mood-content"
    :class="{ 'mood-content--empty': empty }"
  >
    <path
      class="mood-content__background"
      d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26Z"
    />
    <g class="mood-content__face">
      <path
        d="M17.0002 12.0022C17.1033 11.7092 17.2948 11.4555 17.5483 11.2759C17.8017 11.0964 18.1047 11 18.4153 11C18.7259 11 19.0288 11.0964 19.2823 11.2759C19.5357 11.4555 19.7272 11.7092 19.8303 12.0022"
      />
      <path
        d="M8.00049 11.9943C8.10459 11.703 8.29621 11.451 8.54907 11.2728C8.80193 11.0947 9.10371 10.999 9.41302 10.999C9.72234 10.999 10.0241 11.0947 10.2769 11.2728C10.5298 11.451 10.7214 11.703 10.8255 11.9943"
      />
      <path
        d="M19 16C18.5399 16.9004 17.8277 17.6587 16.9441 18.1889C16.0604 18.719 15.0406 19 14 19C12.9594 19 11.9396 18.719 11.0559 18.1889C10.1723 17.6587 9.46014 16.9004 9 16"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MoodContent",
  props: {
    empty: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.mood-content {
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;

  &__background {
    fill: $color-mood-content;
    stroke: $color-mood-content;
  }

  &__face {
    stroke-width: 1.3;
    stroke: $base-color;
  }

  &--empty {
    .mood-content__background {
      fill: none;
      stroke: $base-color;
    }

    &:hover {
      .mood-content__background,
      .mood-content__face {
        stroke: $color-mood-content;
      }
    }
  }
}
</style>

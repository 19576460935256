import { ref, watch } from "vue";
import dayjs from "dayjs";

import { db } from "@/firebase";
import getUser from "./getUser";

export default function useMoodForToday() {
  const { userDetails } = getUser();
  const mood = ref({});
  let unsubscribe = null;

  watch(
    [userDetails],
    () => {
      if (!userDetails.value) return;
      if (unsubscribe) unsubscribe();
      unsubscribe = db
        .collection(`users/${userDetails.value.id}/moods`)
        .doc(`${dayjs().format("YYYY-MM-DD")}`)
        .onSnapshot(doc => (mood.value = doc.data().value));
    },
    { immediate: true }
  );

  const setMood = value =>
    db
      .collection(`users/${userDetails.value.id}/moods`)
      .doc(`${dayjs().format("YYYY-MM-DD")}`)
      .set({
        value
      });

  return [mood, setMood];
}

<template>
  <OnboardingModal />
  <div class="container">
    <div class="wrapper">
      <OverviewUser />
    </div>
  </div>
</template>

<script>
import OnboardingModal from "@/views/OnboardingModal";
import OverviewUser from "@/views/overview/OverviewUser";

export default {
  name: "CompanyOverview",
  components: {
    OnboardingModal,
    OverviewUser
  }
};
</script>

<template>
  <button
    aria-label="close"
    class="closeModal clickable"
    @click="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <IconClose :size="12" color="#2A1F60" />
  </button>
</template>

<script>
import useKeydown from "@/composables/useKeydown";

import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: {
    IconClose
  },
  setup(props, { emit }) {
    const close = () => emit("close");
    useKeydown([
      {
        key: "Escape",
        fn: close
      }
    ]);
  }
};
</script>

<style lang="scss" scoped>
.closeModal {
  align-items: center;
  background: #d4d5dd;
  border: none;
  border-radius: 150px;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: -16px;
  top: -16px;
  width: 32px;

  &:hover {
    background-color: #ffb77d;
  }
}
</style>

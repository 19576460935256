<template>
  <Enter>
    <div class="mainTitle">
      Let's get started!
    </div>
    <h5 class="nomargin">We don’t need much to get you started</h5>
    <div class="signup-box">
      <form @submit.prevent="handleSubmit">
        <fieldset v-if="isCurrentStep(1)">
          <div class="panel-body">
            <h4>Step 1/3: Create your account</h4>
            <br />
            <div class="form-group">
              <label for="email">Your Email</label>
              <input
                type="email"
                required
                placeholder="email@company.com"
                v-model="email"
                ref="projectList"
              />
            </div>
            <div class="form-group">
              <label for="password">Pick a password</label>
              <input
                type="password"
                required
                placeholder="password"
                v-model="password"
              />
            </div>
            <BaseButton
              @click.prevent="nextStep()"
              @keydown.enter="nextStep()"
              aria-label="continue"
              modifiers="primary"
            >
              Continue
            </BaseButton>
          </div>
        </fieldset>
        <fieldset v-if="isCurrentStep(2)">
          <div class="panel-body">
            <h4>Step 2/3: A bit about yourself</h4>
            <br />
            <div class="form-group">
              <label for="displayName">Your First Name</label>
              <input
                type="text"
                required
                placeholder="Jane"
                v-model="displayName"
              />
            </div>
            <div class="form-group">
              <label for="lastName">Your Last Name</label>
              <input
                type="text"
                required
                placeholder="Doe"
                v-model="lastName"
              />
            </div>
            <BaseButton
              modifiers="secondary"
              aria-label="previous"
              @click.prevent="previousStep()"
            >
              Previous
            </BaseButton>
            <BaseButton
              modifiers="primary"
              aria-label="next"
              @click.prevent="nextStep()"
            >
              Continue
            </BaseButton>
          </div>
        </fieldset>
        <fieldset v-if="isCurrentStep(3)">
          <div class="panel-body">
            <h4>Step 3/3: Let's setup your team</h4>
            <br />
            <div class="form-group">
              <label for="workingHoursPerDay"
                >How many hours/day do you work?</label
              >
              <input
                v-model.number="workingHoursPerDay"
                type="number"
                min="1"
                max="24"
                placeholder="Maximum working hours per day"
              />
            </div>
            <div class="form-group">
              <label for="displayName">Your Company Name</label>
              <input
                type="text"
                required
                placeholder="e.g.: Wildbit"
                v-model="company"
              />
            </div>
            <BaseButton
              modifiers="secondary"
              aria-label="previous"
              @click.prevent="previousStep()"
              >Previous</BaseButton
            >
            <BaseButton aria-label="signup" modifiers="primary">
              Let's go!
            </BaseButton>
          </div>
          <div class="error">{{ error }}</div>
        </fieldset>
      </form>
    </div>
    <div class="separatorLine"></div>
    <p>
      Already registered?
      <router-link :to="{ name: 'Login' }" class="under">Login</router-link>
    </p>
  </Enter>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { timestamp } from "@/firebase";

import BaseButton from "@/components/designsystem/BaseButton";
import Enter from "./Enter";
import getUser from "@/composables/getUser";
import useCollection from "@/composables/useCollection";
import useSignup from "@/composables/useSignup";

export default {
  components: { Enter, BaseButton },
  setup() {
    const router = useRouter();
    const { error, signup, isPending } = useSignup();
    const { addDoc: addUser } = useCollection("users");
    const { addDoc: addCompany } = useCollection("companies");
    const { user, userDetails } = getUser();

    const step = ref(1);
    const isCurrentStep = value => step.value === value;
    const previousStep = () => (step.value -= 1);
    const nextStep = () => (step.value += 1);
    const displayName = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const password = ref("");
    const company = ref("");
    const workingHoursPerDay = ref("");
    const avatar = ref("");
    const companyId = ref("");
    const planId = ref("");
    const price = ref("");

    const handleSubmit = async () => {
      await signup(email.value, password.value, displayName.value);

      if (!error.value) {
        await addUser({
          userId: user.value.uid,
          email: user.value.email,
          firstName: user.value.displayName,
          lastName: lastName.value,
          photoURL: avatar.value,
          createdAt: timestamp(),
          workingHoursPerDay: workingHoursPerDay.value
        });
        const userDefaultCompany = await addCompany({
          name: company.value,
          createdAt: timestamp(),
          owner: user.value.uid,
          members: [user.value.uid]
        });

        // const addedCompany = await addCompany({
        //   name: company.value,
        //   createdAt: timestamp(),
        //   owner: user.value.uid,
        //   members: [user.value.uid]
        // });

        // const res = await updateUser({
        //   companyId: addedCompany.id
        // });
        // console.log(res);

        router.replace({
          name: "CompanyOverview",
          params: { companyId: userDefaultCompany.id }
        });
      } else {
        console.log("There was an error!");
      }
    };

    return {
      step,
      isCurrentStep,
      previousStep,
      nextStep,
      displayName,
      firstName,
      lastName,
      email,
      password,
      company,
      workingHoursPerDay,
      avatar,
      companyId,
      planId,
      price,
      handleSubmit,
      error,
      isPending,
      userDetails
    };
  },
  mounted() {
    const projectListRef = this.$refs.projectList;
    projectListRef.focus();
  }
};
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 12px;
}
h5 {
  font-family: "GreycliffCF-Regular";
  margin: 24px 0 42px 0;
  font-size: 21px;
  font-weight: 100;
  line-height: 32px;
}
.mainTitle {
  color: $base-color;
  font-family: Rational-Display-Book;
  font-size: 48px;
  font-weight: 900;
  line-height: 62px;
  box-sizing: border-box;
  margin-bottom: 0;
  width: 100%;
}
.nomargin {
  margin-top: 0;
}
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
label {
  height: auto;
  margin: 0;
}
input {
  border-radius: 6px;
  box-sizing: border-box;
  margin: 8px 0 16px 0;
  width: 432px;
}
a.under {
  color: $base-color;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.secondary {
  border: 2px solid $base-color;
  color: $base-color;
  margin-right: 8px;
}
</style>

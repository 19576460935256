<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 8 8"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.58363 7.64434C6.87652 7.93724 7.3514 7.93724 7.64429 7.64434C7.93718 7.35145 7.93718 6.87658 7.64429 6.58368L4.99263 3.93202L7.64429 1.28035C7.93718 0.98746 7.93718 0.512586 7.64429 0.219693C7.3514 -0.0732003 6.87652 -0.0732005 6.58363 0.219693L3.93197 2.87136L1.28033 0.219724C0.987436 -0.0731688 0.512562 -0.073169 0.219669 0.219724C-0.0732242 0.512618 -0.0732236 0.987492 0.219669 1.28038L2.87131 3.93202L0.219672 6.58365C-0.0732212 6.87655 -0.0732214 7.35142 0.219672 7.64431C0.512565 7.93721 0.987439 7.93721 1.28033 7.64431L3.93197 4.99268L6.58363 7.64434Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconClose",
  props: {
    size: {
      type: Number,
      default: 8
    },
    color: {
      type: String,
      default: "white"
    }
  }
};
</script>

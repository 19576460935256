<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mood-worried"
    :class="{ 'mood-worried--empty': empty }"
  >
    <path
      class="mood-worried__background"
      d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26Z"
    />
    <g class="mood-worried__face">
      <path
        d="M12.4958 19.581C13.1 18.5282 13.9869 17.6656 15.0561 17.0909C16.1253 16.5162 17.334 16.2525 18.5454 16.3294"
      />
      <path
        d="M11 9.99194C10.8907 10.2849 10.6877 10.5387 10.419 10.7182C10.1504 10.8977 9.82922 10.9941 9.49997 10.9941C9.17072 10.9941 8.84957 10.8977 8.58089 10.7182C8.3122 10.5387 8.10926 10.2849 8 9.99194"
      />
      <path
        d="M19.0049 9.99011C18.8943 10.2814 18.6908 10.5334 18.4223 10.7115C18.1538 10.8897 17.8333 10.9854 17.5049 10.9854C17.1764 10.9854 16.856 10.8897 16.5874 10.7115C16.3189 10.5334 16.1154 10.2814 16.0049 9.99011"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MoodWorried",
  props: {
    empty: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.mood-worried {
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;

  &__background {
    fill: $color-mood-worried;
    stroke: $color-mood-worried;
  }

  &__face {
    stroke-width: 1.3;
    stroke: $base-color;
  }

  &--empty {
    .mood-worried__background {
      fill: none;
      stroke: $base-color;
    }

    &:hover {
      .mood-worried__background,
      .mood-worried__face {
        stroke: $color-mood-worried;
      }
    }
  }
}
</style>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.709 16.9815H15.709L15.7089 16.9766C15.6962 16.5337 15.7991 16.2551 15.9309 16.0515C16.0729 15.8322 16.2657 15.6692 16.5096 15.4688C16.5225 15.4582 16.5357 15.4474 16.5492 15.4363C16.8847 15.1618 17.3818 14.755 17.3818 14.0182C17.3818 13.6013 17.2158 13.2406 16.9216 12.992C16.6369 12.7513 16.2689 12.6455 15.9 12.6455C15.0783 12.6455 14.6132 13.1415 14.3528 13.5737L13.8391 13.2768C14.1844 12.5217 14.9456 11.9546 16.1091 11.9546C16.6907 11.9546 17.2335 12.1063 17.6169 12.3873C17.9826 12.6555 18.2273 13.0556 18.2273 13.6455C18.2273 14.4217 17.8537 14.7725 17.3554 15.2238C17.3444 15.2337 17.3333 15.2437 17.3221 15.2538C16.9202 15.6172 16.3868 16.0994 16.3111 16.9369L16.3111 16.9368L16.3105 16.9442L16.2946 17.1546H15.7123L15.709 16.9815ZM14.5984 13.7163C14.6007 13.7175 14.6019 13.7182 14.6019 13.7183C14.6019 13.7183 14.6007 13.7177 14.5984 13.7163ZM15.4091 19.5091C15.4091 19.217 15.6624 18.9455 16.0091 18.9455C16.344 18.9455 16.6 19.2143 16.6 19.5091C16.6 19.7747 16.3554 20.0455 16.0091 20.0455C15.648 20.0455 15.4091 19.7784 15.4091 19.5091ZM16 25.5C10.758 25.5 6.5 21.242 6.5 16C6.5 10.758 10.758 6.5 16 6.5C21.242 6.5 25.5 10.758 25.5 16C25.5 21.242 21.242 25.5 16 25.5ZM16 7.31818C11.2148 7.31818 7.31818 11.2148 7.31818 16C7.31818 20.7852 11.2148 24.6818 16 24.6818C20.7852 24.6818 24.6818 20.7852 24.6818 16C24.6818 11.2148 20.7852 7.31818 16 7.31818Z"
      fill="white"
      stroke="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconHelp"
};
</script>

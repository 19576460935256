<template>
  <div class="mood-tracker-today">
    <h4 class="mood-tracker-today__title">How do you feel today?</h4>
    <h6 class="mood-tracker-today__subtitle">
      By selecting how you feel, we can better inform how to work today or even
      tomorrow
    </h6>
    <ul class="mood-tracker-today__choices">
      <li v-for="mood in moods" :key="mood">
        <MoodItem
          :value="mood"
          :empty="!isTodayMood(mood)"
          @click="setTodayMood(mood)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";

import useMoodForToday from "@/composables/useMoodForToday";

import MoodItem from "@/components/mood/MoodItem.vue";

export default {
  name: "MoodTrackerToday",
  components: { MoodItem },
  setup() {
    const moods = ref(["happy", "content", "soso", "worried", "stressed"]);

    const [todayMood, setTodayMood] = useMoodForToday();
    const isTodayMood = mood => mood === todayMood.value;

    return { moods, isTodayMood, todayMood, setTodayMood };
  }
};
</script>

<style lang="scss" scoped>
.mood-tracker-today {
  margin-top: 17px;
  background: white;
  box-shadow: $shadow-sm;
  box-sizing: border-box;
  border-radius: $b9;
  height: 244px;
  padding: 24px 32px;

  &__title {
    margin-bottom: 12px;
  }

  &__subtitle {
    margin: 0;
    line-height: 20px;
  }

  &__choices {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }
}
</style>

import { ref, computed } from "vue";
import { useRoute } from "vue-router";

import { db } from "@/firebase";

import getUser from "./getUser";

const userCompanies = ref([]);

export default function useCompanies() {
  const route = useRoute();
  const { user } = getUser();

  db.collection("companies")
    .where("members", "array-contains", user.value.uid)
    .onSnapshot(snap => {
      userCompanies.value = snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    });

  const company = computed(() =>
    userCompanies.value.find(company => company.id === route.params.companyId)
  );

  return {
    userCompanies,
    company
  };
}

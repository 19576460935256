<template>
  <Modal :show="currentModal === 'Tasks'" @close="currentModal = null">
    <div class="powerWrapper">
      <CloseModal @close="closeModal" />
      <h4>Today's schedule</h4>
      <div v-if="tasks">
        <TaskList :tasks="tasks" />
      </div>
    </div>
  </Modal>
  <Modal :show="currentModal === 'Mood'" @close="currentModal = null">
    <div class="powerWrapper">
      <CloseModal @close="closeModal" />
      <h4>Today's mood</h4>
      <div v-if="tasks">
        <MoodTrackerToday />
      </div>
    </div>
  </Modal>

  <div class="power-menu">
    <div class="powerMain">
      <div v-if="isNavOpen" class="powerMenu">
        <div class="closePower clickable" @click="isNavOpen = false">
          <IconClose />
        </div>
        <a href="#" @click="openPowerNavItem('Tasks')">
          <IconDrawer />
        </a>
        <a href="#" @click="openPowerNavItem('Mood')">
          <IconMood />
        </a>
      </div>
      <div v-else class="powerMenu">
        <a href="#" @click="isNavOpen = true">
          <IconZap />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import getUser from "@/composables/getUser";
import getCollection from "@/composables/getCollection";

import Modal from "@/components/commons/Modal";
import CloseModal from "@/components/designsystem/CloseModal";
import TaskList from "@/components/overview/personal/TaskList.vue";
import MoodTrackerToday from "@/components/mood/MoodTrackerToday.vue";

import IconMood from "@/components/icons/IconMood.vue";
import IconDrawer from "@/components/icons/IconDrawer.vue";
import IconClose from "@/components/icons/IconClose.vue";
import IconZap from "@/components/icons/IconZap.vue";

export default {
  components: {
    Modal,
    CloseModal,
    TaskList,
    MoodTrackerToday,
    IconZap,
    IconDrawer,
    IconClose,
    IconMood
  },
  setup() {
    const { user } = getUser();
    const currentModal = ref("");

    const isNavOpen = ref(false);
    const toggleNav = () => (isNavOpen.value = !isNavOpen.value);

    const { error, documents: tasks } = getCollection("tasks", [
      "userId",
      "==",
      user.value.uid
    ]);

    const openPowerNavItem = item => {
      currentModal.value = item;
      isNavOpen.value = false;
    };

    const closeModal = () => {
      currentModal.value = "";
    };

    return {
      currentModal,
      isNavOpen,
      user,
      error,
      tasks,
      toggleNav,
      closeModal,
      openPowerNavItem
    };
  }
};
</script>

<style lang="scss" scoped>
.closePower {
  background: linear-gradient(113.61deg, #ff5c00 0%, #ffa800 100%);
  border-radius: 6px 0 0 6px;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  left: -20px;
  position: absolute;
  width: 20px;
}
.closePower:hover {
  background: #afaad1;
}
h4 {
  margin-bottom: 24px;
}
.powerWrapper {
  background: white;
  border-radius: 9px;
  padding: 32px;
  position: relative;
  max-width: 456px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.powerInbox {
  font-family: GreycliffCF-Bold;
}

.bottomLink {
  align-items: center;
  background: #f3f4f8;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #d4d5dd;
  bottom: 0;
  box-sizing: border-box;
  color: $base-color;
  display: flex;
  height: 57px;
  justify-content: center;
  left: 0;
  padding: 0 32px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.vsm-arrow {
  top: -24px;
  left: -322px;
  position: relative;
  margin: 0 0 0 -6px;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 4px 0 0 0;
  background: #fff;
  -webkit-box-shadow: -3px -3px 5px rgba(82, 95, 127, 0.04);
  box-shadow: -3px -3px 5px rgba(82, 95, 127, 0.04);
  will-change: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  pointer-events: none;
}
</style>

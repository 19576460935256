<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mood-happy"
    :class="{ 'mood-happy--empty': empty }"
  >
    <path
      d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26Z"
      class="mood-happy__background"
    />
    <g class="mood-happy__face">
      <path
        d="M19.3463 15.0174C19.3985 14.9992 19.4549 14.9968 19.5084 15.0106C19.562 15.0244 19.6102 15.0537 19.6471 15.0949C19.684 15.136 19.7079 15.1872 19.7157 15.242C19.7236 15.2967 19.7151 15.3525 19.6913 15.4024C19.1548 16.4906 18.3244 17.407 17.2941 18.0478C16.2637 18.6886 15.0746 19.0282 13.8613 19.0282C12.6479 19.0282 11.4588 18.6886 10.4285 18.0478C9.3982 17.407 8.56782 16.4906 8.0313 15.4024C8.00552 15.353 7.9953 15.2971 8.002 15.2418C8.00871 15.1866 8.032 15.1346 8.06884 15.0929C8.10568 15.0512 8.15431 15.0217 8.2083 15.0082C8.2623 14.9947 8.31913 14.9979 8.37127 15.0174C11.9338 16.2534 15.8088 16.2534 19.3713 15.0174H19.3463Z"
      />
      <path
        d="M8.49512 11C8.49512 10.6022 8.65314 10.2206 8.93445 9.93933C9.21575 9.65803 9.59729 9.5 9.99512 9.5C10.3929 9.5 10.7745 9.65803 11.0558 9.93933C11.3371 10.2206 11.4951 10.6022 11.4951 11"
      />
      <path
        d="M16.4951 11C16.4951 10.6022 16.6531 10.2206 16.9344 9.93933C17.2158 9.65803 17.5973 9.5 17.9951 9.5C18.3929 9.5 18.7745 9.65803 19.0558 9.93933C19.3371 10.2206 19.4951 10.6022 19.4951 11"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MoodHappy",
  props: {
    empty: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.mood-happy {
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;

  &__background {
    fill: $color-mood-happy;
    stroke: $color-mood-happy;
  }

  &__face {
    stroke-width: 1.3;
    stroke: $base-color;
  }

  &--empty {
    .mood-happy__background {
      fill: none;
      stroke: $base-color;
    }

    &:hover {
      .mood-happy__background,
      .mood-happy__face {
        stroke: $color-mood-happy;
      }
    }
  }
}
</style>

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAgtm2Z7e8NAWFXz1GersA8QCW_kYn31eo",
  authDomain: "dawn-dev-6f890.firebaseapp.com",
  projectId: "dawn-dev-6f890",
  storageBucket: "dawn-dev-6f890.appspot.com",
  messagingSenderId: "431493887143",
  appId: "1:431493887143:web:16ac6cc7ab90f4cd13d782"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const createTimestamp = firebase.firestore.Timestamp.fromDate;

export { db, auth, storage, timestamp, createTimestamp };

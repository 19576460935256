<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mood-soso"
    :class="{ 'mood-soso--empty': empty }"
  >
    <path
      class="mood-soso__background"
      d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26Z"
    />
    <g class="mood-soso__face">
      <path d="M10 17.8711L17.9696 17.1738" />
      <path
        d="M9.0824 11.0253C9.25051 10.7944 9.48202 10.6326 9.74496 10.5621C10.0079 10.4917 10.2893 10.5161 10.5504 10.632C10.8114 10.7479 11.0391 10.9496 11.2022 11.2092"
      />
      <path
        d="M17.0824 11.0253C17.2505 10.7944 17.482 10.6326 17.745 10.5621C18.0079 10.4917 18.2893 10.5161 18.5504 10.632C18.8114 10.7479 19.0391 10.9496 19.2022 11.2092"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MoodSoso",
  props: {
    empty: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.mood-soso {
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;

  &__background {
    fill: $color-mood-soso;
    stroke: $color-mood-soso;
  }

  &__face {
    stroke-width: 1.3;
    stroke: $base-color;
  }

  &--empty {
    .mood-soso__background {
      fill: none;
      stroke: $base-color;
    }

    &:hover {
      .mood-soso__background,
      .mood-soso__face {
        stroke: $color-mood-soso;
      }
    }
  }
}
</style>

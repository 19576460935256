<template>
  <button class="base-button" :class="modifiers.split(' ')" v-bind="$attrs">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    modifiers: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.base-button {
  background-color: $base-color;
  border: none;
  border-radius: $b9;
  color: white;
  font-family: GreycliffCF-Bold;
  font-size: $t14;
  font-weight: 500;
  height: $s40;
  cursor: pointer;
  padding: 6px $s24;
  transition: all 0.35s;

  &:hover {
    background: #ffb77d;
    color: $base-color;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.secondary {
    background: white;
    border: 2px solid $base-color;
    color: $base-color;

    &:hover {
      background: $base-color;
      color: white;
    }
  }

  &.textonly {
    background: none;
    color: $base-color;
    margin-top: 32px;
    padding: 0 12px;
  }
}
</style>

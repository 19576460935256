import { createRouter, createWebHistory } from "vue-router";
import getUser from "@/composables/getUser";
import { getUserDefaultCompany } from "@/composables/useDefaultCompany";

import Login from "@/views/enter/Login";
import Signup from "@/views/enter/Signup";

import AppLayout from "@/layout/AppLayout";
import CompanyOverview from "@/views/CompanyOverview.vue";

const requireAuth = (to, from, next) => {
  const { user } = getUser();

  document.title = `${to.meta.title}`;
  if (!user.value) {
    next("/");
  } else {
    next();
  }
};

const requireNoAuth = async (to, from, next) => {
  const { user } = getUser();

  if (!user.value) {
    next();
  } else {
    const defaultCompany = await getUserDefaultCompany();
    next({ name: "CompanyOverview", params: { companyId: defaultCompany.id } });
  }
};

const routes = [
  {
    path: "/",
    redirect: { name: "Login" }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: requireNoAuth,
    meta: {
      title: "Dawn - Log In"
    }
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    beforeEnter: requireNoAuth,
    meta: {
      title: "Dawn - Sign Up"
    }
  },
  {
    path: "/c/:companyId",
    component: AppLayout,
    beforeEnter: requireAuth,
    redirect: { name: "CompanyOverview" },
    children: [
      {
        path: "",
        name: "CompanyOverview",
        component: CompanyOverview,
        meta: {
          title: "Overview"
        }
      },
      {
        path: "planner",
        name: "CompanyPlanner",
        component: () => import("@/views/planner/Planner.vue"),
        meta: {
          title: "Personal Planner"
        }
      },
      {
        path: "projects",
        name: "CompanyProjects",
        component: () => import("@/views/projects/Projects.vue"),
        meta: {
          title: "Projects"
        }
      },
      {
        path: "projects/:projectId",
        name: "CompanyProjectDetails",
        component: () => import("@/views/projects/ProjectDetails.vue"),
        meta: {
          title: "Project Details"
        }
      },
      {
        path: "mood",
        name: "UserMood",
        component: () => import("@/views/moodinsider/Mood.vue"),
        meta: {
          title: "Mood Insider"
        }
      },
      {
        path: "profile",
        name: "Settings",
        component: () => import("@/views/settings/Settings.vue"),
        meta: {
          title: "My Settings"
        }
      },
      {
        path: "workspace",
        name: "SettingsWork",
        component: () => import("@/views/settings/SettingsWork.vue"),
        meta: {
          title: "Workspace Settings"
        }
      },
      {
        path: "tasks/:taskId",
        name: "TaskDetails",
        component: () => import("@/views/tasks/TaskDetails.vue"),
        meta: {
          title: "Task Details"
        }
      },
      {
        path: "help",
        name: "Help",
        component: () => import("@/views/Help.vue"),
        meta: {
          title: "Help"
        }
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      title: "Something went wrong"
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
});

export default router;

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6ZM8 16C8 11.5817 11.5817 8 16 8C20.4183 8 24 11.5817 24 16C24 20.4183 20.4183 24 16 24C11.5817 24 8 20.4183 8 16Z"
      fill="#FFF"
    />
    <path
      d="M20.5839 18C19.8124 19.7659 18.0503 21 16 21C13.9497 21 12.1876 19.7659 11.416 18"
      stroke="#FFF"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle cx="12.5" cy="13.5" r="1.5" fill="#FFF" />
    <circle cx="19.5" cy="13.5" r="1.5" fill="#FFF" />
  </svg>
</template>

<script>
export default {
  name: "IconMood"
};
</script>

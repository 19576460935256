<template>
  <div class="main-menu open">
    <div class="main-logo">
      <router-link :to="{ name: 'CompanyOverview' }">
        <div class="navIcon">
          <IconDawnLogo />
        </div>
        <span v-if="company">{{ company.name }}</span>
      </router-link>
      <!-- <router-link
        v-for="company in userCompanies"
        :key="company.id"
        :to="{ name: 'CompanyOverview', params: { companyId: company.id } }"
      >
        {{ company.name }}
        <span v-if="currentCompany.id === company.id">(current)</span>
      </router-link> -->
    </div>
    <div class="main-nav">
      <router-link :to="{ name: 'CompanyProjects' }">
        <div class="navIcon">
          <IconFolder />
        </div>
        All Projects
      </router-link>

      <router-link :to="{ name: 'CompanyPlanner' }">
        <div class="navIcon">
          <IconCalendar />
        </div>
        My Planner
      </router-link>

      <router-link :to="{ name: 'UserMood' }">
        <div class="navIcon">
          <IconMood />
        </div>
        Mood Insider
      </router-link>
    </div>
    <div class="secondaryNav">
      <router-link v-if="false" :to="{ name: 'Help' }">
        <div class="navIcon">
          <IconHelp />
        </div>
        Help
      </router-link>
      <router-link :to="{ name: 'Settings' }">
        <div class="navIcon avatarDefault">
          <UserProfileImage v-if="userDetails" :user="userDetails" />
        </div>
        Profile
      </router-link>
    </div>
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import useCompanies from "@/composables/useCompanies";

import UserProfileImage from "@/components/commons/UserProfileImage.vue";

import IconDawnLogo from "@/components/icons/IconDawnLogo.vue";
import IconFolder from "@/components/icons/IconFolder.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import IconMood from "@/components/icons/IconMood.vue";
import IconHelp from "@/components/icons/IconHelp.vue";

export default {
  name: "LeftNav",
  components: {
    IconDawnLogo,
    IconFolder,
    IconCalendar,
    IconMood,
    IconHelp,
    UserProfileImage
  },
  setup() {
    const { user, userDetails } = getUser();
    const { company, userCompanies } = useCompanies();

    return {
      user,
      userDetails,
      userCompanies,
      company
    };
  }
};
</script>

<template>
  <DailyPrompt />
  <LeftNav />
  <NewNav />
  <PowerNav />
  <router-view :key="$route.fullPath" />
</template>

<script>
import { ref } from "vue";

import DailyPrompt from "@/components/commons/DailyPrompt";
import LeftNav from "@/components/navigations/LeftNav.vue";
import NewNav from "@/components/navigations/NewNav.vue";
import PowerNav from "@/components/navigations/PowerNav";

export default {
  name: "AppLayout",
  components: {
    DailyPrompt,
    LeftNav,
    NewNav,
    PowerNav
  },
  setup() {
    const showAdmin = ref(true);

    return {
      showAdmin
    };
  }
};
</script>

<style lang="scss" scoped>
.newOnboarding {
  background: white;
  border-radius: 16px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: 620px;
}
</style>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 22.2222C15.0001 22.65 15.4501 23 16.0001 23C16.5501 23 17.0001 22.65 17.0001 22.2222V17H22.2223C22.6501 17 23.0001 16.55 23.0001 16C23.0001 15.45 22.6501 15 22.2223 15H17.0001V9.77778C17.0001 9.35 16.5501 9 16.0001 9C15.4501 9 15.0001 9.35 15.0001 9.77778V15H9.7779C9.35012 15 9.00012 15.45 9.00012 16C9.00012 16.55 9.35012 17 9.7779 17H15.0001V22.2222Z"
      fill="#2a1f60"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPlus"
};
</script>

<template>
  <div class="task-list">
    <h4>Your current tasks</h4>

    <div class="task-list__items">
      <div v-if="tasks.length > 0">
        <div
          class="task clickable overviewtask"
          v-for="task in tasks.slice(0, 3)"
          :key="task.id"
        >
          <router-link
            :to="{ name: 'TaskDetails', params: { taskId: task.id } }"
            class="linkedTask"
          >
            <div class="name">{{ task.name }}</div>
            <div class="duration">{{ task.estimatedTime }} hrs</div>
          </router-link>
        </div>
        <div class="blockMore clickable">
          <router-link :to="{ name: 'CompanyPlanner' }"
            >See more tasks</router-link
          >
        </div>
      </div>
      <div v-else class="notasks">
        <h5>You currently don't have any tasks</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tasks: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.task-list {
  background: #f8f1ed;
  box-sizing: border-box;
  border-radius: $b9;
  height: 342px;
  padding: 24px 32px;

  &__items {
    margin-top: 24px;
    min-height: 202px;
  }
}
.notasks {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 202px;
}
.overviewtask {
  padding: 19px 0;
  display: flex;
  height: 100%;
  align-items: center;
}
.linkedTask {
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 100%;
  width: 100%;
}
</style>

<template>
  <transition name="fade">
    <div v-if="show" class="app-modal">
      <div class="app-modal__backdrop" @click="$emit('close')"></div>
      <div class="app-modal__content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import useKeydown from "@/composables/useKeydown";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    useKeydown([
      {
        key: "Escape",
        fn: () => emit("close")
      }
    ]);
  }
};
</script>

<style lang="scss" scoped>
.app-modal {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(42, 31, 96, 0.84);
    transition: opacity 0.3s ease;
  }

  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

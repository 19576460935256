<template>
  <img
    v-if="imageSource"
    :src="imageSource"
    :alt="`${user.firstName}'s profile picture`"
    :style="imageStyles"
    @error="onImageLoadError"
  />
  <svg
    v-else
    :width="size"
    :height="size"
    :style="imageStyles"
    viewBox="0 0 29 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <circle cx="14.4" cy="14.4" r="14.4" :style="{ fill: 'rgb(42, 31, 96)' }" />
    <text
      x="50%"
      y="55%"
      dominant-baseline="middle"
      text-anchor="middle"
      style="font-family:'GreycliffCF-Bold', 'Arial', sans-serif;font-size:12px; fill: white"
    >
      {{ userInitials }}
    </text>
    ^
  </svg>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "UserProfileImage",
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: 28
    }
  },
  setup(props) {
    const imageSource = ref(props.user.photoURL);

    const imageStyles = computed(() => ({
      width: `${props.size}px`,
      height: `${props.size}px`,
      borderRadius: `${props.size / 2}px`
    }));

    const onImageLoadError = () => (imageSource.value = null);

    const userInitials = computed(() =>
      [props.user.firstName[0], props.user.lastName[0]].join("")
    );

    return {
      imageSource,
      imageStyles,
      onImageLoadError,
      userInitials
    };
  }
};
</script>

<style lang="scss" scoped>
.image-placeholder {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: $base-color;
  border: 2px solid white;
  color: white;
  font-family: "GreycliffCF-Bold";
  font-size: 12px;
}
</style>
